import Service from "../Service";
import ServiceReport from "../ServiceReport";

const resource = "LgsOrderPurcharse/";
const resourceReport = "orderpurchase/";

export default {

    pagination(parameters) {
        return Service.post(resource + "pagination", parameters, {
            params: {},
        });
    },

    byID(parameters, requestID) {
        return Service.post(resource + "byid", parameters, {
            params: {},
        });
    },

    attendedByID(parameters, requestID) {
        return Service.post(resource + "attendedbyid", parameters, {
            params: {},
        });
    },


    save(obj) {
        return Service.post(resource + "save", obj, {
            params: {},
        });
    },

    anulated(obj) {
        return Service.put(resource + "anulated", obj, {
            params: {},
        });
    },

    saveVoucher(obj) {
        return Service.post(resource + "savevoucher", obj, {
            params: {},
        });
    },

    savefiles(obj, requestID) {
        return Service.post(resource + "savefiles", obj, {
            params: {},
        });
    },

    saveAdvacement(obj) {
        return Service.post(resource + "saveadvacement", obj, {
            params: {},
        });
    },

    printPDF(OrdID) {
        return ServiceReport.get(resourceReport + "pdforderpurcharse",  {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                OrdID: OrdID
            },
        });
    },


};