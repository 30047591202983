<template>
<div class="header-dots">

    <change-password :user="user" :userName="UsrName" @close="showChangePassword=false" v-if="showChangePassword" />

    <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" width="100%" transition="dialog-bottom-transition" persistent>
            <!-- Logistica -->
            <requirement-edit @close="dialog = false" v-if="this.htmlComponent == 'requirement-edit'" :value="this.DocEntry"></requirement-edit>
            <lgs-order-purcharse-edit @close="dialog = false" v-if="this.htmlComponent == 'orderpurcharse-edit'" :value="this.DocEntry" :order="{}"></lgs-order-purcharse-edit>
            <lgs-comparison-chart @close="dialog = false;" v-if="this.htmlComponent == 'cotization-edit'" :value="this.DocEntry"></lgs-comparison-chart>
            <lgs-order-purcharse-attended-edit @onClose="dialog = false" v-if="this.htmlComponent == 'attended-edit'" :value="this.DocEntry" :order="{}" />
        </v-dialog>
    </div>

    <!--  <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-xl" variant="link" no-caret right v-if="!usrExtern">
        <span slot="button-content">
            <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <div class="icon-wrapper-bg bg-primary"></div>
                <i class="lnr-menu text-primary"></i>
            </div>
        </span>
        <div class="dropdown-menu-header">
            <div class="dropdown-menu-header-inner bg-plum-plate">
                <div class="menu-header-image dd-header-bg-1"></div>
                <div class="menu-header-content text-white">
                    <h5 class="menu-header-title">Grid Dashboard</h5>
                    <h6 class="menu-header-subtitle">Easy grid navigation inside dropdowns</h6>
                </div>
            </div>
        </div>
        <div class="grid-menu grid-menu-xl grid-menu-3col">
            <div class="no-gutters row">
                <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                        <i class="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>Automation
                    </button>
                </div>
                <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                        <i class="pe-7s-piggy icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>Reports
                    </button>
                </div>
                <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                        <i class="pe-7s-config icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>Settings
                    </button>
                </div>
                <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                        <i class="pe-7s-browser icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>Content
                    </button>
                </div>
                <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                        <i class="pe-7s-hourglass icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>Activity
                    </button>
                </div>
                <div class="col-sm-6 col-xl-4">
                    <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                        <i class="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i>Contacts
                    </button>
                </div>
            </div>
        </div>
        <ul class="nav flex-column"> 
            <li class="nav-item-divider nav-item"></li>
            <li class="nav-item-btn text-center nav-item">
                <button class="btn-shadow btn btn-primary btn-sm">Follow-ups</button>
            </li>
        </ul>
    </b-dropdown> -->
    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-xl" variant="link" no-caret right v-if="!usrExtern">
        <span slot="button-content">
            <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <div class="icon-wrapper-bg bg-primary"></div>
                <i class="lnr-menu text-primary"></i>
            </div>
        </span>
        <div class="dropdown-menu-header mb-0">
            <div class="dropdown-menu-header-inner bg-blue">
                <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
                <div class="menu-header-content text-dark">
                    <h5 class="menu-header-title">{{UsrName}}</h5>
                </div>
            </div>
        </div>
        <div class="scroll-area-xs" style="height: 350px;">
            <ul class="nav flex-column">
                <li class="nav-item-header nav-item">Diseño</li>

                <li class="nav-item">
                </li>
                <v-card max-height="400" style="overflow-x:hidden">
                    <v-card-text>
                        <v-row style="margin-top : -25px">
                            <v-col class="s-col-form">
                                <h6>Lenguage Alertas</h6>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top : -20px">
                            <v-col class="s-col-form">
                                <v-radio-group row v-model="configUser.CfgLanguage">
                                    <v-radio label="Español" color="primary" value="es"></v-radio>
                                    <!-- <v-radio
                                        label="Ingles"
                                        color="red"
                                        value="en"
                                    ></v-radio> -->
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top : -20px">
                            <v-col class="s-col-form">
                                <h6>Alertas</h6>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top : -20px">
                            <v-col class="s-col-form">
                                <v-switch v-model="configUser.CfgPersistent" label="Dialog Persistente" color="primary" :value="configUser.CfgPersistent" hide-details small></v-switch>
                            </v-col>
                            <v-col class="s-col-form">
                                <v-switch v-model="configUser.CfgAlertType" label="Alerta Normal" color="primary" :value="configUser.CfgAlertType" hide-details></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="s-col-form">
                                <h6>Alertas Posiciòn</h6>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top : -20px">
                            <v-col class="s-col-form">
                                <v-radio-group row v-model="configUser.CfgAlertAlign">
                                    <v-radio label="top" color="primary" value="top"></v-radio>
                                    <v-radio label="top-start" color="red" value="top-start"></v-radio>
                                    <v-radio label="top-end" color="orange" value="top-end"></v-radio>
                                    <v-radio label="center" color="success" value="center"></v-radio>
                                    <v-radio label="center-start" color="success" value="center-start"></v-radio>
                                    <v-radio label="center-end" color="success" value="center-end"></v-radio>
                                    <v-radio label="bottom" color="success" value="bottom"></v-radio>
                                    <v-radio label="bottom-start" color="success" value="bottom-start"></v-radio>
                                    <v-radio label="bottom-end" color="success" value="bottom-end"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <!--   <v-card-actions>
                        <bc-button class="mt-4" variant="gradient" color="success" fullWidth size="lg" @click="saveUserConfiguration()">Guardar</bc-button>
                    </v-card-actions> -->
                </v-card>
            </ul>

        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>

        <div class="grid-menu grid-menu-2col overflow-hidden pl-3 pr-3">
            <!-- <bc-button variant="gradient" color="success" fullWidth size="md" @click="logout">Cerrar sesión</bc-button> -->
            <bc-button class="mt-4" variant="gradient" style="background:#3364b7" color="info" fullWidth size="lg" @click="saveConfiguration">Guardar</bc-button>
        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>
    </b-dropdown>
    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-end dropdown-menu-xl" style="" variant="link" no-caret left v-if="!usrExtern">
        <span slot="button-content" @click="clickNotification()">
            <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <div class="icon-wrapper-bg bg-danger"></div>
                <i class="lnr-bullhorn text-danger ml-2"></i>
                <div class="badge badge-dot badge-dot-sm badge-danger"></div>
            </div>
        </span>
        <div class="dropdown-menu-header mb-0">
            <div class="dropdown-menu-header-inner bg-deep-red">
                <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
                <div class="menu-header-content text-dark">
                    <h5 class="menu-header-title">Notificaciones</h5>
                    <h6 class="menu-header-subtitle">
                        Tienes
                        <b>{{count}}</b> notificacion(es) por aprobar.
                    </h6>
                </div>
            </div>
        </div>
        <div class="nav-justified">
            <b-tabs class="card-header-tab-animation" card>
                <b-tab title="Messages" class="p-1" active>
                    <div class="scroll-gradient">
                        <div class="scroll-area-md">

                            <div v-if="count <= 0">
                                <div class="no-results">
                                    <div class="swal2-icon swal2-success swal2-animate-success-icon">
                                        <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);">
                                        </div>
                                        <span class="swal2-success-line-tip"></span>
                                        <span class="swal2-success-line-long"></span>
                                        <div class="swal2-success-ring"></div>
                                        <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                                        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                                    </div>
                                    <!-- <div class="results-subtitle">All caught up!</div> -->
                                    <div class="results-title mt-6">No se encontrarón notificaciones pendientes por aprobar.</div>
                                </div>
                            </div>

                            <div class="scroll" v-for="(item, index) in Object.keys(itemsAlert_group)" :key="index">
                                <h6 class="ml-6 mt-3 align-center">{{item}}</h6>
                                <section class="ps-container scrollbar-container ps ps--theme_default" v-for="(d, index) in itemsAlert_group[item]" :key="index" style="margin-bottom:-30px">
                                    <!-- <v-divider></v-divider> -->
                                    <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                        <div class="vertical-timeline-item vertical-timeline-element">

                                            <div>
                                                <span class="vertical-timeline-element-icon bounce-in">
                                                    <i class="badge badge-dot badge-dot-xl badge-primary"></i>
                                                    <v-avatar style="overflow: unset !important" :color="d.AleColor">
                                                        <span class="white--text">{{ d.TrsAbbrTable }}</span>
                                                    </v-avatar>

                                                </span>

                                                <div class="vertical-timeline-element-content bounce-in pl-4">

                                                    <div @click="showAlert(d)">
                                                        <span class="vertical-timeline-element-icon bounce-in">
                                                            <!--  <i class="badge badge-dot badge-dot-xl badge-warning"></i> -->
                                                        </span>

                                                        <div class="vertical-timeline-element-content bounce-in">
                                                            <!-- <p>

                                                                <label>{{d.CpyName}}</label>
                                                            </p> -->
                                                            <p style="margin-top:0px">
                                                                <b class="text-success mt-0">{{d.UsrName}}</b>
                                                            </p>
                                                            <p style="margin-top:-8px;margin-left:-2px">
                                                                <label>{{d.CpyName}}</label>
                                                            </p>
                                                            <v-divider style="margin-top:-10px"></v-divider>
                                                            <p style="margin-top:-8px;">
                                                                <label>{{ d.AleMessage }}</label>
                                                                <span class="text-danger"><label class="text-danger">{{d.AtsDateTransaction !== null ? $moment(d.AtsDateTransaction).format($const.FormatDateTimeDB) : ''}}</label> </span>
                                                            </p>
                                                            <span class="vertical-timeline-element-date"></span>
                                                        </div>
                                                    </div>

                                                    <!--  <a style="text-decoration:underline" @click="showAlert(d)">
                                                        <h6 class="text-success" style="font-family:Cursive">{{d.UsrName}}</h6>
                                                        <b class="text-danger">12:00 PM</b>
                                                    </a>
                                                    <p style="margin-top: -6px;text-transform: lowercase;-webkit-text-stroke: thin;">{{ d.AleMessage }} - {{d.AtsDateTransaction !== null ? $moment(d.AtsDateTransaction).format($const.FormatDateDB) : ''}}</p>
                                                    <span class="vertical-timeline-element-date"></span> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                            <!--  <VuePerfectScrollbar class="scrollbar-container">
                                 <div class="vertical-timeline-item vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-warning"></i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <p>
                                                    Another meeting today, at
                                                    <b class="text-danger">12:00 PM</b>
                                                </p>
                                                <p>
                                                    Yet another one, at
                                                    <span class="text-success">15:00 PM</span>
                                                </p>
                                                <span class="vertical-timeline-element-date"></span>
                                            </div>
                                        </div>
                                    </div>
                            </VuePerfectScrollbar> -->
                        </div>
                    </div>
                </b-tab>
                <!-- <b-tab title="Events" class="p-3">
                    <div class="scroll-gradient">
                        <div class="scroll-area-sm">
                            <VuePerfectScrollbar class="scrollbar-container" v-once>
                                <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                    <div class="vertical-timeline-item vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-success"></i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">All Hands Meeting</h4>
                                                <p> Lorem ipsum dolor sic amet, today at
                                                    <a href="javascript:void(0);">12:00 PM</a>
                                                </p>
                                                <span class="vertical-timeline-element-date"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-item vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-warning"></i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <p> Another meeting today, at
                                                    <b class="text-danger">12:00 PM</b>
                                                </p>
                                                <p> Yet another one, at
                                                    <span class="text-success">15:00 PM</span>
                                                </p>
                                                <span class="vertical-timeline-element-date"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-item vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-danger"></i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">Build the production release</h4>
                                                <p>
                                                    Lorem ipsum dolor sit amit,consectetur eiusmdd tempor incididunt
                                                    ut labore et dolore magna elit enim at minim veniam quis
                                                    nostrud
                                                </p>
                                                <span class="vertical-timeline-element-date"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-item vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-primary"></i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title text-success">Something not important</h4>
                                                <p>
                                                    Lorem ipsum dolor sit amit,consectetur elit enim at minim veniam
                                                    quis nostrud
                                                </p>
                                                <span class="vertical-timeline-element-date"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-item vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-success"></i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">All Hands Meeting</h4>
                                                <p>
                                                    Lorem ipsum dolor sic amet, today at
                                                    <a href="javascript:void(0);">12:00 PM</a>
                                                </p>
                                                <span class="vertical-timeline-element-date"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-item vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-warning"></i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <p>
                                                    Another meeting today, at
                                                    <b class="text-danger">12:00 PM</b>
                                                </p>
                                                <p>
                                                    Yet another one, at
                                                    <span class="text-success">15:00 PM</span>
                                                </p>
                                                <span class="vertical-timeline-element-date"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-item vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-danger"></i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title">Build the production release</h4>
                                                <p>
                                                    Lorem ipsum dolor sit amit,consectetur eiusmdd tempor incididunt
                                                    ut labore et dolore magna elit enim at minim veniam quis
                                                    nostrud
                                                </p>
                                                <span class="vertical-timeline-element-date"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-item vertical-timeline-element">
                                        <div>
                                            <span class="vertical-timeline-element-icon bounce-in">
                                                <i class="badge badge-dot badge-dot-xl badge-primary"></i>
                                            </span>
                                            <div class="vertical-timeline-element-content bounce-in">
                                                <h4 class="timeline-title text-success">Something not important</h4>
                                                <p>
                                                    Lorem ipsum dolor sit amit,consectetur elit enim at minim veniam
                                                    quis nostrud
                                                </p>
                                                <span class="vertical-timeline-element-date"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </VuePerfectScrollbar>
                        </div>
                    </div>
                </b-tab>
                -->
                <b-tab title="Status" class="pt-3">
                    <div>
                        <div class="no-results">
                            <div class="swal2-icon swal2-success swal2-animate-success-icon">
                                <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);">
                                </div>
                                <span class="swal2-success-line-tip"></span>
                                <span class="swal2-success-line-long"></span>
                                <div class="swal2-success-ring"></div>
                                <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                                <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                            </div>
                            <!-- <div class="results-subtitle">All caught up!</div> -->
                            <div class="results-title mt-6">¡No hay errores del sistema!</div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>

            <ul class="nav flex-column">
                <li class="nav-item-divider nav-item mt-0"></li>
                <li class="nav-item-btn text-center nav-item">
                    <!-- <button class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm">View Latest Changes</button> -->
                </li>
            </ul>
        </div>
    </b-dropdown>

    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-end dropdown-menu-xl" variant="link" no-caret right>
        <span slot="button-content">
            <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <img width="38" class="rounded-circle" src="../../../assets/images/User.png" alt />
            </div>
        </span>
        <div class="dropdown-menu-header mb-0">
            <div class="dropdown-menu-header-inner bg-blue">
                <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
                <div class="menu-header-content text-dark">
                    <h5 class="menu-header-title">{{UsrName}}</h5>
                </div>
            </div>
        </div>
        <div class="scroll-area-xs" style="height: 350px;">
            <ul class="nav flex-column">
                <li class="nav-item-header nav-item">Menú</li>

                <li class="nav-item">
                </li>
                <li class="nav-item">
                    <a style="color: #1976d2;" class="nav-link" @click="changePass()">Cambiar Password</a>
                </li>
                <li class="nav-item-header nav-item" v-if="!usrExtern">Firma</li>

                <li class="nav-item">
                </li>

                <li class="nav-item" v-if="!usrExtern">
                    <v-file-input :accept="'.jpg'" v-model="AttachImg" label="Firma" hide-details>
                        <template slot="append">
                            <label class="mr-2" style="color: red">(.jpg)</label>
                        </template>
                    </v-file-input>

                    <!--  <v-col cols="12" v-if="!usrExtern">
                        <v-card-actions>
                            <v-btn outlined v-if="user.NtpID > 0" elevation="0" small color="#5175af" class="capitalize" dark @click="saveImage()">
                                Guardar Firma

                            </v-btn>

                        </v-card-actions>
                        <span class="ml-2" style="color:red">Para visualizar cambios debe cerrar sesión.</span>
                        <v-img v-if="personImageFirma != null" contain :src="personImageFirma" height="200"></v-img>
                    </v-col> -->

                </li>
                <!--  -->

                <v-card max-height="400" style="overflow-x:hidden" v-if="!usrExtern">
                    <v-card-text>
                        <v-row style="margin-top : -25px">
                            <v-col class="s-col-form">
                                <v-card-actions>
                                    <v-btn outlined v-if="user.NtpID > 0" elevation="0" small color="#5175af" class="capitalize" dark @click="saveImage()">
                                        Guardar Firma
                                    </v-btn>

                                </v-card-actions>
                                <span class="ml-2" style="color:red;font-size:10px">Para visualizar cambios debe cerrar sesión.</span>
                                <v-img width="300" v-if="personImageFirma != null" contain :src="personImageFirma" height="200"></v-img>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </ul>

        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>

        <div class="grid-menu grid-menu-2col overflow-hidden pl-3 pr-3">
            <bc-button class="mt-4" variant="gradient" style="background:#3364b7" color="info" fullWidth size="lg" @click="logout">Cerrar sesión</bc-button>
        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>
    </b-dropdown>

    <!-- <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-end dropdown-menu-xl" variant="link" no-caret left>
        <span slot="button-content">
            <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <img width="38" class="rounded-circle" src="../../../assets/images/User.png" alt />
            </div>
        </span>
        <div class="dropdown-menu-header mb-0">
            <div class="dropdown-menu-header-inner bg-blue">
                <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
                <div class="menu-header-content text-dark">
                    <h5 class="menu-header-title">{{UsrName}}</h5>
                </div>
            </div>
        </div>
        <ul class="nav flex-column">
            <li class="nav-item-header nav-item">Menu</li>

            <li class="nav-item">
                <a style="color: #1976d2;" class="nav-link" @click="changePass()">Cambiar Password</a>
            </li>
            <li class="nav-item-header nav-item">Firma</li>
            <li class="nav-item" v-if="!usrExtern">
                <v-file-input :accept="'.jpg'" v-model="AttachImg" label="Firma" hide-details>
                    <template slot="append">
                        <label class="mr-2" style="color: red">(.jpg)</label>
                    </template>
                </v-file-input>

            </li>
            <v-col v-if="!usrExtern">
                <v-card-actions>
                    <v-btn outlined v-if="user.NtpID > 0" elevation="0" small color="#5175af" class="capitalize" dark @click="saveImage()">
                        Guardar Firma

                    </v-btn>

                </v-card-actions>
                <span class="ml-2" style="color:red">Para visualizar cambios debe cerrar sesión.</span>
                <v-img v-if="personImageFirma != null" contain :src="personImageFirma" height="200"></v-img>
            </v-col>
        </ul>
        <div class="grid-menu grid-menu-2col overflow-hidden pl-3 pr-3">
            <bc-button class="mt-4" variant="gradient" style="background:#3364b7" color="info" fullWidth size="lg" @click="logout">Cerrar sesión</bc-button>
        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>
    </b-dropdown> -->
</div>
</template>

<script>
//Services

import _sTransaction from "@/services/Security/SecAlertService";
import _sGenWorker from "@/services/General/GenWorkerService.js";
import _sHelper from "@/services/HelperService";
import _sUserConfiguration from "@/services/Security/SecUserConfigurationService"

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CountryFlag from "vue-country-flag";
//Components
import RequirementEdit from "@/views/Logistics/LgsRequirement/LgsRequirementEdit.vue";
import LgsOrderPurcharseEdit from "@/views/Logistics/LgsOrderPurcharse/LgsOrderPurcharseEdit.vue";
import LgsComparisonChart from "@/views/Logistics/LgsCotization/LgsComparisonChart.vue";
import LgsOrderPurcharseAttendedEdit from "@/views/Logistics/LgsOrderPurcharseAttended/LgsOrderPurcharseAttendedEdit.vue";

import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faArrowUp,
    faCog
} from "@fortawesome/free-solid-svg-icons";
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

library.add(faArrowUp, faCog);

import ChangePassword from "@/views/Auth/ChangePassword";

export default {
    components: {
        VuePerfectScrollbar,
        CountryFlag,
        "font-awesome-icon": FontAwesomeIcon,
        ChangePassword,
        RequirementEdit,
        LgsOrderPurcharseEdit,
        LgsComparisonChart,
        LgsOrderPurcharseAttendedEdit
    },
    props: {
        configUser: {
            type: Object,
            default: null,
        },
        user: {
            type: Object,
            default: {
                PrsDocumentNumber: ""
            },
        },
    },
    data: () => ({
        usrExtern: false,
        UsrName: "",
        itemsAlert: [],
        itemsAlert_group: [],
        count: 0,
        DocEntry: 0,
        htmlComponent: "",
        showChangePassword: false,
        dialog: false,
        AttachImg: null,
        personImageFirma: null,

        fill1: {
            gradient: ["#00b09b", "#96c93d"]
        },
        fill2: {
            gradient: ["#ff0844", "#ffb199"]
        },
        fill3: {
            gradient: ["#f6d365", "#fda085"]
        },
        showDrawerSection: false
    }),
    created() {
        this.UsrName = localStorage.getItem('UsrName');
        this.usrExtern = JSON.parse(localStorage.getItem("UsrExtern"))

        if (this.user.GenWorkerCE !== null)
            this.AttachImg = {
                name: this.user.GenWorkerCE.WrkAttachImg
            };

        if (!this.usrExtern && this.user.GenWorkerCE !== null) {
            //alert()
            this.getImageFirma(this.user.GenWorkerCE.WrkAttachImg)
        }

    },
    methods: {

        changePass() {
            this.showChangePassword = true
        },

        logout() {
            this.$emit("logout");
        },

        clickNotification() {
            console.log(this.user);

            let dr = {
                length: 100, // this.lengthItemsAlert,
                start: 0, // this.indexAlert * this.lengthItemsAlert,
                filter: {
                    UsrID: this.$fun.getUserID()
                },
            };

            _sTransaction.pagination(dr, this.$fun.getUserID()).then((r) => {
                this.itemsAlert = []
                this.itemsAlert.push(...r.data.Result.data);
                this.count = this.itemsAlert.length
                this.itemsAlert_group = _.groupBy(this.itemsAlert, "TrsName");

                console.log(this.itemsAlert);
            });
        },

        showAlert(al) {
            console.log("showAlert", al);
            this.DocEntry = al.DocEntry;
            this.htmlComponent = al.TrsComponentName;

            this.dialog = true;
        },

        /*  uploadFile() {
             let files = [];

             if (this.AttachImg) files.push(this.AttachImg);

             var formData = new FormData();
             let i = 0;
             files.forEach((element) => {
                 formData.append(i.toString(), element);
                 i++;
             });
             _sGeneral.uploadfile("123", this.$fun.getUserID(), formData, 4);
         }, */

        getImageFirma(PrsFirma) {
            _sHelper.getPhotoWorkerFirma(PrsFirma).then((rsp) => {
                const blob = new Blob([rsp.data], {
                    type: "image/jpg",
                });

                let image = blob;
                var reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onloadend = () => {
                    this.personImageFirma = reader.result;
                };
            });
        },

        saveImage() {

            if (this.AttachImg == null) {
                this.$fun.alert("Seleccione una firma", "warning")
                return
            }

            if (!this.AttachImg.name.includes(this.user.PrsDocumentNumber)) {
                this.$fun.sweetAlert("[Nombre de archivo incorrecto]=>[" + this.user.PrsDocumentNumber + ".jpg]", "warning");
                return;
            }

            var array = []
            array.push({
                RqdID: 0,
                RqdDescription: "Firma Digital",
                RqdRequired: 1,
                RqdAttach: this.AttachImg,
                RqdSize: 2097152,
                RqdAccept: ".jpg",
            })

            this.$fun.uploadFiles(array, 9).then(resp => {
                if (resp.status == 200) {
                    console.log("firma cargada", resp.data[0].RqdAttach);

                    _sGenWorker.updateSignature({
                        WrkID: this.user.GenWorkerCE.WrkID,
                        WrkAttachImg: resp.data[0].RqdAttach,
                        UsrUpdateID: this.$fun.getUserID()
                    }).then(resp => {
                        console.log("response firma", resp.data.Result.Message);
                        //this.getImageFirma(resp.data.Result.Message)
                    })
                }
            })

            /* _userService.updateUserFirma(this.$fun.getUserID(), this.AttachImg.name).then(resp => {
                    if (resp.status == 200) {
                        this.uploadFile()
                        this.$fun.alert("Actualizado correctamente", "success")
                        this.$router.go(0)
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                }); */
        },

        saveConfiguration() {
            this.configUser.CfgToolbar = this.generatetext
            this.configUser.CfgAlertType = this.configUser.CfgAlertType ? 1 : 0;
            this.configUser.CfgPersistent = this.configUser.CfgPersistent ? 1 : 0;

            _sUserConfiguration
                .save(this.configUser)
                .then(resp => {
                    if (resp.status == 200) {
                        localStorage.clear();
                        localStorage.removeItem("token");
                        this.logout()
                        return;
                    }
                })
        }
    }
};
</script>
