import swal from "sweetalert2";
import router from "../router";
import moment from "moment";
import _fun from "@/plugins/fun";
import _sHelper from "@/services/HelperService.js";
import _constapp from "@/plugins/const-es";

/* var _constapp = null;

var languaje = localStorage.getItem('languaje');
if (languaje == "es") {
    import ("./const-es.js")
    .then((module) => {
        _constapp = module.default
    });
} else {
    import ("./const-en.js")
    .then((module) => {
        _constapp = module.default
    });
} */

export default {

    /* **************************************************************************
     * Contiene las funciones javascript comunes para el proyecto,
     * todos las funciones empiezan con el prefijo "g"
     ****************************************************************************/
    alertFull(msg, type, subtitle) {
        swal.fire({
            title: msg,
            icon: type,
            allowOutsideClick: false,
        });
    },
    alert(msg, type, toa = true, position = 'top') {
        type = type || "info";
        var timer = 2000;
        var ok = false;
        var cancel = false;
        switch (type) {
            case "error":
                var timer = null;
                ok = true;
                break;
            case "warning":
                var timer = 3000;
                break;
            case "info":
                var timer = 10000;
                break;
            case "question":
                var timer = null;
                ok = true;
                cancel = true;
                break;
        }
        var toast = null;
        toast = swal.mixin({
            toast: toa,
            position: position,
            showConfirmButton: ok,
            showCancelButton: cancel,
            cancelButtonText: "Cancelar",
            timer: timer,       
            timerProgressBar: true,
            didOpen: function(toast) {
                toast.addEventListener('mouseenter', function() {
                    swal.stopTimer();
                });
                toast.addEventListener('mouseleave', function() {
                    swal.resumeTimer();
                });
            },
            customClass: {
                // Aplicamos la clase de estilo personalizado
               popup: 'custom-sweetalert',
            },
        })

        return toast.fire({
            icon: type,
            title: msg,
            
        })
    },

    sweetAlert(msg, type) {
        var typeAlert = localStorage.getItem('AlertType');

        if (typeAlert !== null) {
            return new Promise((resolve, reject) => {
                _fun.alert(msg, type, JSON.parse(localStorage.getItem('AlertType')), localStorage.getItem('AlertAlign')).then((val) => {
                    resolve(val);
                }, (e) => {
                    reject(e)
                });
            })
        } else {
            _fun.alert("Error al cargar configuración inicial.", "error", false, "center")
            window.location.reload();
        }

    },
    getUserID() {
        return localStorage.UsrID;
    },

    getCompanyID() {
        
        var CpyID = localStorage.CpyID
        if(CpyID == "undefined"){
            return  0
        }else{
            return localStorage.CpyID
        }
        
    },

    getCompanyName() {
        
        var CpyName = localStorage.CpyCpyNameID
        if(CpyName == "undefined"){
            return  ""
        }else{
            return localStorage.CpyName
        }
        
    },

    getUserName() {
        return localStorage.UsrName;
    },

    getUserFullName() {
        return localStorage.UsrFullName;
    },

    getDate() {
        return moment().format(_constapp.FormatDateDB);
    },
    getDayInitialMounth() {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

        return firstDayOfMonth.toISOString().substr(0, 10); // Formato yyyy-mm-dd
    },

    getUserInfo() {
        return JSON.parse(localStorage.UserInfo);
    },

    getSecurity() {
        let objTmp = {
            IsAllowAdd:false,
            IsAllowDelete:false,
            IsAllowEdit:false,
            IsAllowExport:false,
            IsAllowSave:false,
            IsAllowView:false,
            IsLevelAdmin:false,
            IsLevelArea:false,
            IsLevelAssistant:false,
            IsLevelBoss:false,
            IsLevelManager:false
        }

        var options = router.history.current.params.security;

        if(options == undefined){
            return objTmp;
        }else{
            return options;
        }        
    },
    isAdmin() {
        return router.history.current.params.security.IsLevelAdmin;
    },

    formatDateView(value) {
        let val = value;
        if (!_fun.isEmptyVal(value)) {
            val = moment(value, _constapp.FormatDateDB).format(_constapp.FormatDateView);
        }
        return val;
    },
    formatTimeView(value) {
        let val = value;
        if (!_fun.isEmptyVal(value)) {
            val = moment(value, _constapp.FormatTimeDB).format(_constapp.FormatTimeView);
        }
        return val;
    },
    formatDateTimeView(value) {
        let val = value;
        if (!_fun.isEmptyVal(value)) {
            val = moment(value, _constapp.FormatDateTimeDB).format(_constapp.FormatDateTimeView);
        }
        return val;
    },

    calculateAge: (date) => {
        var age = 0;
        age = moment().diff(moment(date, _constapp.FormatDateDB), "years");
        return age;
    },

    isEmptyVal: (val) => {
        var is = val === undefined || val === null || val === "";
        if (!is) is = val.length == 0;
        return is;
    },

    isUri(uri) {
        try {
            new URL(uri);
            return true;
        } catch (error) {
            return false;
        }
    },

    isValidURL(str) {
        // from https://stackoverflow.com/a/14582229 but removed the ip address section
        var pattern = new RegExp(
          '^((https?:)?\\/\\/)?' + // protocol
          '(?:\\S+(?::\\S*)?@)?(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}' + // domain name
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\\\#[-a-z\\\\d_]*)?', // fragment locator
          'i' // case insensitive
        );
        return !!pattern.test(str);
      },

    gTrim(cadena) {
        return cadena.replace(/^\s+|\s+$/g, "");
    },
    gRound(numero, decimales) {
        decimales = (!decimales ? 2 : decimales);
        var n = Math.round(numero * Math.pow(10, decimales)) / Math.pow(10, decimales);
        var nn = n.toFixed(decimales);
        return nn;
    },

    gValidarEmail(email) {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(email)) {
            return false;
        }
        return true;
    },
    gRandomColor() {
        var letters = '0123456789ABCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },


    //Validar Archivos
    isValidExtension(items) {
        var isValid = false

        for (let index = 0; index < items.length; index++) {

            if (items[index].RqdRequired == 1 && items[index].RqdAttach == null) {
                alert(items[index].RqdDescription + '. Obligatorio');
                items[index].RqdAttach = null
                isValid = true
            }

            if (items[index].RqdAttach !== null && items[index].RqdAttach !== undefined) {
                let archivo = items[index].RqdAttach.name
                if (archivo !== null) {
                    var extension = archivo.substring(archivo.lastIndexOf('.'), archivo.length);
                    if (items[index].RqdAccept.split(',').indexOf(extension) < 0) {
                        _fun.alert("["+items[index].RqdDescription + ']. Archivo inválido. No se permite la extensión ' + extension, "warning");
                        items[index].RqdAttach = null
                        isValid = true
                    }
                }
            }

        }
        return isValid
    },

    isValidSize(items) {
        var isValid = false
        for (let index = 0; index < items.length; index++) {
            if (items[index].RqdAttach !== null && items[index].RqdAttach !== undefined) {
                if (items[index].RqdAttach.size > items[index].RqdSize) {
                    _fun.alert("["+items[index].RqdDescription + "]. El tamaño del archivo debe ser inferior a " + items[index].RqdSize / 1048576 + " MB!", "warning")
                    isValid = true

                }
            }
        }
        return isValid
    },

    isValidLong(items) {
        var isValid = false
        for (let index = 0; index < items.length; index++) {
            if (items[index].RqdAttach !== null && items[index].RqdAttach !== undefined) {
                if (items[index].RqdAttach.name.length > items[index].RqdLength) {
                    _fun.alert("["+items[index].RqdDescription + "]. El tamaño del nombre de archivo debe ser inferior a " + items[index].RqdLength + " carácteres.", "warning")
                    isValid = true

                }
            }
        }
        return isValid
    },

    uploadFilesProvider(itemsAttach, NroVoucher, Ruc, OrdDocumentNumber) {
        let files = [];

        for (let index = 0; index < itemsAttach.length; index++) {
            if (itemsAttach[index].RqdAttach !== null) {
                files.push({
                    RqdID: itemsAttach[index].RqdID,
                    RqdAttach: itemsAttach[index].RqdAttach
                })
            }
        }
        if (_fun.isValidExtension(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        if (_fun.isValidSize(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        if (_fun.isValidLong(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        var formData = new FormData();
        files.forEach((element) => {
            formData.append(element.RqdID, element.RqdAttach);
        });

        return new Promise((resolve, reject) => {
            _sHelper.uploadFileProvider(formData, NroVoucher, Ruc, OrdDocumentNumber).then((resp) => {
                resolve(resp);
            }, (e) => {
                reject(e)
            });
        })
    },

    uploadFiles(itemsAttach, parameter) {
        let files = [];

        for (let index = 0; index < itemsAttach.length; index++) {
            if (itemsAttach[index].RqdAttach !== null) {
                files.push({
                    RqdID: itemsAttach[index].RqdID,
                    RqdAttach: itemsAttach[index].RqdAttach
                })
            }
        }
        if (_fun.isValidExtension(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        if (_fun.isValidSize(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        if (_fun.isValidLong(itemsAttach)) {
            return new Promise((resolve, reject) => {})
        }

        var formData = new FormData();
        files.forEach((element) => {
            formData.append(element.RqdID, element.RqdAttach);
        });

        return new Promise((resolve, reject) => {
            _sHelper.uploadFile(formData, parameter).then((resp) => {
                resolve(resp);
            }, (e) => {
                reject(e)
            });
        })
    },

    downloadFile(file, type, filename) {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        //link.setAttribute("download", filename + "_" + Math.floor(Math.random() * 999999 + 1) + "." + type.Extension); //or any other extension
        link.setAttribute("download", filename + "." + type.Extension); //or any other extension
        document.body.appendChild(link);
        link.click();
    },

    download(file, filename) {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();
    },
    allowedDates: (val) => {
        const currentDate = new Date();
        const selectedDate = new Date(val);

        // Obtener la fecha
        const tomorrow = new Date();
        tomorrow.setDate(currentDate.getDate() - 1);
        tomorrow.setHours(0, 0, 0, 0);
       


        // Deshabilitar las fechas anteriores a hoy
        if (selectedDate < tomorrow) {
            return false;
        }
        return selectedDate.getDate();
    },

    searchObject(array, OptCode) {
        for (var i = 0; i < array.length; i++) {
          var elemento = array[i];
          if (elemento.OptCode === OptCode) {
                return elemento.options;
          }
    
          if (Array.isArray(elemento.child)) {
                var encontrado = _fun.searchObject(elemento.child, OptCode);
                if (encontrado) {
                    return encontrado;
                }
          }
        }
      
        return null;
    },

    getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
}