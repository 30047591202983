import { render, staticRenderFns } from "./LgsOrderPurcharseEdit.vue?vue&type=template&id=212415a9&scoped=true&"
import script from "./LgsOrderPurcharseEdit.vue?vue&type=script&lang=js&"
export * from "./LgsOrderPurcharseEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212415a9",
  null
  
)

export default component.exports